import { useQuery } from '@tanstack/react-query';
import apiClient from '../axios/api-client';

const useGetLoginAllowed = () =>
  useQuery(['loginAllowed'], async () => {
    const result = await apiClient.get(`/login-allowed/`);
    return result.data;
  });

export default useGetLoginAllowed;
