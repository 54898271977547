import { removeAuthState, removeAuthAccessToken, removeAuthExpireTimestamp } from './auth-session';
import { getWebAuth } from './webAuth';

export const logout = async () => {
  const webAuth = await getWebAuth();
  if (webAuth) {
    webAuth.logout({ returnTo: `${window.location.origin}/` });
    removeAuthState();
    removeAuthExpireTimestamp();
    removeAuthAccessToken();
  }
};
