import { useState } from 'react';
import apiClient from '../axios/api-client';

interface UseGetBranchesActivityReturn {
  error: boolean;
  success: boolean;
  onClickBranchesActivity: () => Promise<void>;
}

export const useGetBranchesActivity = (): UseGetBranchesActivityReturn => {
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const onClickBranchesActivity = async (): Promise<void> => {
    try {
      await apiClient.get('/branches_activity?create_file=true');
      setSuccess(true);
      setError(false);
    } catch (err) {
      setError(true);
      setSuccess(false);
    }
  };

  return {
    error,
    success,
    onClickBranchesActivity,
  };
};
