import { WebAuth } from 'auth0-js';
import type { AuthOptions } from 'auth0-js';
import { getAuthConfig } from './auth-config';

const webAuth = new WebAuth(getAuthConfig());

export const getWebAuth = async () => {
  // only run client side for NextJS
  if (typeof window !== 'undefined') {
    const data = await getAuthConfig();
    if (data) {
      const config: AuthOptions = {
        ...data,
        redirectUri: `${window.location.origin}/auth/callback`,
        responseType: 'token id_token',
      };

      return new WebAuth(config);
    }
  }

  return null;
};

export default webAuth;
